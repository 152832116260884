export function usePageTracking(isHomepage: boolean, trackingData: TrackingData) {
  /**
   * register tracking events after mounting the rendered page
   */
  onMounted(() => {
    trackPageViewAmpl(isHomepage, trackingData)

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden")
        trackScrollDepth()
    })

    window.addEventListener("beforeunload", () => {
      trackScrollDepth()
    })

    if (trackingData.jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled) {
      trackPageViewMatomo(isHomepage, trackingData)
    }
    else {
      window.addEventListener("ccm19WidgetLoaded", () => {
        trackPageViewGTM(isHomepage, trackingData)
      })
      window.addEventListener("ccm19WidgetClosed", () => {
        trackPageViewGTM(isHomepage, trackingData)
      })
    }
  })
}
